var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "design-system" },
    [
      _c("AppTopbar", {
        attrs: {
          title: "Design System",
          buttonText: "New Order",
          buttonIconClass: "icon-add"
        }
      }),
      _c("div", { staticClass: "container-fluid py-70" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("h2", { staticClass: "my-20 d-block w-100" }, [
                _vm._v("Primary")
              ]),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("primary")
                    }
                  }
                },
                [_vm._v(" Apply filter ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "btn-modal mb-10 mr-10",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("primary")
                    }
                  }
                },
                [_vm._v(" No, lead me back ")]
              ),
              _c("b-button", { attrs: { disabled: "" } }, [
                _vm._v("Also Disabled")
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("h2", { staticClass: "my-20 d-block w-100" }, [
                _vm._v("Secondary")
              ]),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10",
                  attrs: { variant: "secondary" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("secondary")
                    }
                  }
                },
                [_vm._v(" Apply filter ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "btn-secondary mb-10 mr-10",
                  attrs: { variant: "secondary" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("secondary")
                    }
                  }
                },
                [_vm._v(" Yes, proceed ")]
              ),
              _c("b-button", { attrs: { disabled: "" } }, [
                _vm._v("Also Disabled")
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("h2", { staticClass: "my-20 d-block w-100" }, [
                _vm._v("Terciary")
              ]),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10",
                  attrs: { variant: "terciary" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("secondary")
                    }
                  }
                },
                [_vm._v(" Apply filter ")]
              ),
              _c("b-button", { attrs: { disabled: "" } }, [
                _vm._v("Also Disabled")
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("h2", { staticClass: "my-20 d-block w-100" }, [
                _vm._v("With Icon")
              ]),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10",
                  attrs: { variant: "icon" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("primary")
                    }
                  }
                },
                [
                  _c("i", { staticClass: "icon-add mr-4 fs16" }),
                  _vm._v(" New Order ")
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("h2", { staticClass: "my-20 d-block w-100" }, [
                _vm._v("Large")
              ]),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10 btn-primary",
                  attrs: { variant: "lg" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("primary")
                    }
                  }
                },
                [_vm._v(" Skip this ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10 btn-secondary",
                  attrs: { variant: "lg" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("secondary")
                    }
                  }
                },
                [_vm._v(" Skip this ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10 btn-primary",
                  attrs: { variant: "lg" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("primary")
                    }
                  }
                },
                [
                  _vm._v(" Next "),
                  _c("i", { staticClass: "icon-arrow ml-5 fs10" })
                ]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10 btn-secondary",
                  attrs: { variant: "lg" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("secondary")
                    }
                  }
                },
                [
                  _vm._v(" Next "),
                  _c("i", { staticClass: "icon-arrow ml-5 fs10" })
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("h2", { staticClass: "my-20 d-block w-100" }, [
                _vm._v("Small")
              ]),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10 btn-primary",
                  attrs: { variant: "sm" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("primary")
                    }
                  }
                },
                [_vm._v(" Skip this ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10 btn-secondary",
                  attrs: { variant: "sm" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("secondary")
                    }
                  }
                },
                [_vm._v(" Skip this ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10 btn-primary",
                  attrs: { variant: "sm" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("primary")
                    }
                  }
                },
                [
                  _vm._v(" Next "),
                  _c("i", { staticClass: "icon-arrow ml-8 fs16" })
                ]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10 btn-secondary",
                  attrs: { variant: "sm" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("secondary")
                    }
                  }
                },
                [
                  _vm._v(" Next "),
                  _c("i", { staticClass: "icon-arrow ml-8 fs16" })
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("h2", { staticClass: "my-20 d-block w-100" }, [
                _vm._v("Tiny")
              ]),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10 btn-primary",
                  attrs: { variant: "xs" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("primary")
                    }
                  }
                },
                [_vm._v(" Skip this ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10 btn-secondary",
                  attrs: { variant: "xs" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("secondary")
                    }
                  }
                },
                [_vm._v(" Skip this ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10 btn-primary",
                  attrs: { variant: "xs" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("primary")
                    }
                  }
                },
                [
                  _vm._v(" Next "),
                  _c("i", { staticClass: "icon-arrow fs10 ml-4" })
                ]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mb-10 mr-10 btn-secondary",
                  attrs: { variant: "xs" },
                  on: {
                    click: function($event) {
                      return _vm.makeToast("secondary")
                    }
                  }
                },
                [
                  _vm._v(" Next "),
                  _c("i", { staticClass: "icon-arrow fs10 ml-4" })
                ]
              )
            ],
            1
          ),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("h2", { staticClass: "my-20 d-block w-100" }, [
                _vm._v("Tags")
              ]),
              _c(
                "b-button",
                { staticClass: "mb-10 mr-10", attrs: { variant: "tag" } },
                [
                  _vm._v(" prepaid "),
                  _c("i", { staticClass: "icon-close fs7" })
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row pb-20" },
            [
              _c("h2", { staticClass: "my-20 w-100" }, [
                _vm._v("Toast Notifications")
              ]),
              _c("b-button", { on: { click: _vm.downloadToast } }, [
                _vm._v("Download Toast")
              ]),
              _c("b-button", { on: { click: _vm.bookedToast } }, [
                _vm._v("Booked Toast")
              ]),
              _c("b-button", { on: { click: _vm.failedToast } }, [
                _vm._v("Failed Toast")
              ])
            ],
            1
          ),
          _c("div", { staticClass: "row p-20 bg-white" }, [
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c("h2", { staticClass: "my-20 w-100" }, [
                  _vm._v(" Checkbox ")
                ]),
                _c(
                  "b-form-checkbox",
                  {
                    attrs: {
                      id: "checkbox-1",
                      name: "checkbox-1",
                      value: "Checked",
                      "unchecked-value": "Not Checked"
                    },
                    model: {
                      value: _vm.chkStatus,
                      callback: function($$v) {
                        _vm.chkStatus = $$v
                      },
                      expression: "chkStatus"
                    }
                  },
                  [_c("strong", [_vm._v(_vm._s(_vm.chkStatus))])]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c(
                  "b-form-group",
                  { attrs: { label: "Individual radios" } },
                  [
                    _c(
                      "b-form-radio",
                      {
                        staticClass: "pb-10",
                        attrs: { name: "some-radios", value: "A" }
                      },
                      [_vm._v(" No ")]
                    ),
                    _c(
                      "b-form-radio",
                      { attrs: { name: "some-radios", value: "B" } },
                      [_vm._v(" Yes ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "row mt-10 p-20 bg-white" },
            [
              _c("h2", { staticClass: "my-20 w-100" }, [_vm._v(" Inputs ")]),
              _c("b-form-input", { attrs: { placeholder: "Text-hint" } }, [
                _vm._v(" Text input ")
              ]),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "label",
                    { staticClass: "pt-20", attrs: { for: "text1" } },
                    [_vm._v("Text Hint")]
                  ),
                  _c(
                    "b-form-input",
                    { attrs: { id: "text1", placeholder: "Text-hint" } },
                    [_vm._v(" Text input ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group pl-20" },
                [
                  _c(
                    "label",
                    { staticClass: "pt-20", attrs: { for: "text2" } },
                    [_vm._v("Label")]
                  ),
                  _c(
                    "b-form-input",
                    {
                      staticClass: "small",
                      attrs: { id: "text2", placeholder: "Text-hint" }
                    },
                    [_vm._v(" Text input ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group pt-20 w-100" },
                [
                  _c("label", [_vm._v("Select")]),
                  _c("b-select", {
                    attrs: { id: "select1", options: _vm.options },
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group w-100" },
                [
                  _c("label", [_vm._v("Select / Multiselect")]),
                  _c("multiselect", {
                    attrs: {
                      searchable: false,
                      "show-labels": false,
                      "allow-empty": false,
                      options: _vm.options,
                      label: "value"
                    },
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group w-50" },
                [
                  _c("label", [_vm._v("Small")]),
                  _c("multiselect", {
                    staticClass: "select-sm",
                    attrs: {
                      searchable: false,
                      "show-labels": false,
                      "allow-empty": false,
                      options: _vm.options,
                      label: "value"
                    },
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row p-20 bg-white" },
            [
              _c("h2", { staticClass: "my-20 w-100" }, [_vm._v("Date Range")]),
              _c(
                "div",
                { staticClass: "form-group w-100" },
                [
                  _c("label", { staticClass: "w-100", attrs: { for: "dp" } }, [
                    _vm._v("Text-hint")
                  ]),
                  _c("date-picker", {
                    attrs: {
                      "prefix-class": "xmx",
                      id: "dp",
                      range: "",
                      placeholder: "yyyy-mm-dd - yyyy-mm-dd"
                    },
                    model: {
                      value: _vm.time3,
                      callback: function($$v) {
                        _vm.time3 = $$v
                      },
                      expression: "time3"
                    }
                  })
                ],
                1
              ),
              _c("date-picker", {
                attrs: { range: "", placeholder: "yyyy-mm-dd - yyyy-mm-dd" },
                model: {
                  value: _vm.time3,
                  callback: function($$v) {
                    _vm.time3 = $$v
                  },
                  expression: "time3"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "row p-20 bg-white" }, [
            _c("h2", { staticClass: "my-20 w-100" }, [_vm._v("Amount")]),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "total-value-field",
                      label: "Total Value",
                      "label-for": "total-value"
                    }
                  },
                  [
                    _c(
                      "b-input-group",
                      { attrs: { append: "₹" } },
                      [
                        _c("b-input", {
                          directives: [
                            {
                              name: "money",
                              rawName: "v-money",
                              value: _vm.money,
                              expression: "money"
                            }
                          ],
                          attrs: { id: "total-value", placeholder: "0.00" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row p-20 bg-white" }, [
            _c("h2", { staticClass: "my-20 w-100" }, [_vm._v("Text Area")]),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "textarea" } }, [
                  _vm._v("Textarea - with label")
                ]),
                _c("b-form-textarea", {
                  attrs: {
                    id: "textarea",
                    placeholder: "Enter something...",
                    rows: "3",
                    "max-rows": "6"
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("h2", { staticClass: "my-20 d-block w-100" }, [_vm._v("Links")]),
      _c("a", { staticClass: "btn-link mr-20", attrs: { href: "#" } }, [
        _c("span", [_vm._v(" Clear filter ")])
      ]),
      _c("a", { staticClass: "btn-link mr-20", attrs: { href: "#" } }, [
        _c("i", { staticClass: "icon-close pr-8" }),
        _c("span", [_vm._v(" Clear filter ")])
      ]),
      _c("a", { staticClass: "btn-link inactive", attrs: { href: "#" } }, [
        _c("span", [_vm._v(" Clear filter ")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }