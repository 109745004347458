<template>
  <div class="design-system">
    <AppTopbar
      title="Design System"
      buttonText="New Order"
      buttonIconClass="icon-add"
    />
    <div class="container-fluid py-70">
      <div class="container">
        <div class="row">
          <h2 class="my-20 d-block w-100">Primary</h2>
          <b-button
            variant="primary"
            @click="makeToast('primary')"
            class="mb-10 mr-10"
          >
            Apply filter
          </b-button>
          <b-button
            variant="primary"
            @click="makeToast('primary')"
            class="btn-modal mb-10 mr-10"
          >
            No, lead me back
          </b-button>
          <b-button disabled>Also Disabled</b-button>
        </div>
        <div class="row">
          <h2 class="my-20 d-block w-100">Secondary</h2>
          <b-button
            variant="secondary"
            @click="makeToast('secondary')"
            class="mb-10 mr-10"
          >
            Apply filter
          </b-button>
          <b-button
            variant="secondary"
            @click="makeToast('secondary')"
            class="btn-secondary mb-10 mr-10"
          >
            Yes, proceed
          </b-button>
          <b-button disabled>Also Disabled</b-button>
        </div>
        <div class="row">
          <h2 class="my-20 d-block w-100">Terciary</h2>
          <b-button
            variant="terciary"
            @click="makeToast('secondary')"
            class="mb-10 mr-10"
          >
            Apply filter
          </b-button>
          <b-button disabled>Also Disabled</b-button>
        </div>
        <div class="row">
          <h2 class="my-20 d-block w-100">With Icon</h2>
          <b-button
            variant="icon"
            @click="makeToast('primary')"
            class="mb-10 mr-10"
          >
            <i class="icon-add mr-4 fs16"></i>
            New Order
          </b-button>
        </div>
        <div class="row">
          <h2 class="my-20 d-block w-100">Large</h2>
          <b-button
            variant="lg"
            @click="makeToast('primary')"
            class="mb-10 mr-10 btn-primary"
          >
            Skip this
          </b-button>
          <b-button
            variant="lg"
            @click="makeToast('secondary')"
            class="mb-10 mr-10 btn-secondary"
          >
            Skip this
          </b-button>
          <b-button
            variant="lg"
            @click="makeToast('primary')"
            class="mb-10 mr-10 btn-primary"
          >
            Next
            <i class="icon-arrow ml-5 fs10"></i>
          </b-button>
          <b-button
            variant="lg"
            @click="makeToast('secondary')"
            class="mb-10 mr-10 btn-secondary"
          >
            Next
            <i class="icon-arrow ml-5 fs10"></i>
          </b-button>
        </div>
        <div class="row">
          <h2 class="my-20 d-block w-100">Small</h2>
          <b-button
            variant="sm"
            @click="makeToast('primary')"
            class="mb-10 mr-10 btn-primary"
          >
            Skip this
          </b-button>
          <b-button
            variant="sm"
            @click="makeToast('secondary')"
            class="mb-10 mr-10 btn-secondary"
          >
            Skip this
          </b-button>
          <b-button
            variant="sm"
            @click="makeToast('primary')"
            class="mb-10 mr-10 btn-primary"
          >
            Next
            <i class="icon-arrow ml-8 fs16"></i>
          </b-button>
          <b-button
            variant="sm"
            @click="makeToast('secondary')"
            class="mb-10 mr-10 btn-secondary"
          >
            Next
            <i class="icon-arrow ml-8 fs16"></i>
          </b-button>
        </div>
        <div class="row">
          <h2 class="my-20 d-block w-100">Tiny</h2>
          <b-button
            variant="xs"
            @click="makeToast('primary')"
            class="mb-10 mr-10 btn-primary"
          >
            Skip this
          </b-button>
          <b-button
            variant="xs"
            @click="makeToast('secondary')"
            class="mb-10 mr-10 btn-secondary"
          >
            Skip this
          </b-button>
          <b-button
            variant="xs"
            @click="makeToast('primary')"
            class="mb-10 mr-10 btn-primary"
          >
            Next
            <i class="icon-arrow fs10 ml-4"></i>
          </b-button>
          <b-button
            variant="xs"
            @click="makeToast('secondary')"
            class="mb-10 mr-10 btn-secondary"
          >
            Next
            <i class="icon-arrow fs10 ml-4"></i>
          </b-button>
        </div>
        <div class="row">
          <h2 class="my-20 d-block w-100">Links</h2>
          <a href="#" class="btn-link mr-20">
            <span>
              Clear filter
            </span>
          </a>
          <a href="#" class="btn-link mr-20">
            <i class="icon-close pr-8"></i>
            <span>
              Clear filter
            </span>
          </a>
          <a href="#" class="btn-link inactive">
            <span>
              Clear filter
            </span>
          </a>
        </div>
        <div class="row">
          <h2 class="my-20 d-block w-100">Tags</h2>
          <b-button variant="tag" class="mb-10 mr-10">
            prepaid
            <i class="icon-close fs7"></i>
          </b-button>
        </div>
        <div class="row pb-20">
          <h2 class="my-20 w-100">Toast Notifications</h2>
          <b-button @click="downloadToast">Download Toast</b-button>
          <b-button @click="bookedToast">Booked Toast</b-button>
          <b-button @click="failedToast">Failed Toast</b-button>
        </div>
        <div class="row p-20 bg-white">
          <div class="col-4">
            <h2 class="my-20 w-100">
              Checkbox
            </h2>
            <b-form-checkbox
              id="checkbox-1"
              v-model="chkStatus"
              name="checkbox-1"
              value="Checked"
              unchecked-value="Not Checked"
            >
              <strong>{{ chkStatus }}</strong>
            </b-form-checkbox>
          </div>
          <div class="col-4">
            <b-form-group label="Individual radios">
              <b-form-radio class="pb-10" name="some-radios" value="A">
                No
              </b-form-radio>
              <b-form-radio name="some-radios" value="B">
                Yes
              </b-form-radio>
            </b-form-group>
          </div>
        </div>
        <div class="row mt-10 p-20 bg-white">
          <h2 class="my-20 w-100">
            Inputs
          </h2>
          <b-form-input placeholder="Text-hint">
            Text input
          </b-form-input>
          <div class="form-group">
            <label class="pt-20" for="text1">Text Hint</label>
            <b-form-input id="text1" placeholder="Text-hint">
              Text input
            </b-form-input>
          </div>
          <div class="form-group pl-20">
            <label class="pt-20" for="text2">Label</label>
            <b-form-input id="text2" class="small" placeholder="Text-hint">
              Text input
            </b-form-input>
          </div>

          <div class="form-group pt-20 w-100">
            <label>Select</label>
            <b-select
              id="select1"
              v-model="selected"
              :options="options"
            ></b-select>
          </div>

          <div class="form-group w-100">
            <label>Select / Multiselect</label>
            <multiselect
              v-model="selected"
              :searchable="false"
              :show-labels="false"
              :allow-empty="false"
              :options="options"
              label="value"
            ></multiselect>
          </div>

          <div class="form-group w-50">
            <label>Small</label>
            <multiselect
            class="select-sm"
              v-model="selected"
              :searchable="false"
              :show-labels="false"
              :allow-empty="false"
              :options="options"
              label="value"
            ></multiselect>
          </div>
        </div>

        <div class="row p-20 bg-white">
          <h2 class="my-20 w-100">Date Range</h2>
          <div class="form-group w-100">
            <label class="w-100" for="dp">Text-hint</label>
            <date-picker
              prefix-class="xmx"
              v-model="time3"
              id="dp"
              range
              placeholder="yyyy-mm-dd - yyyy-mm-dd"
            ></date-picker>
          </div>

          <date-picker
            v-model="time3"
            range
            placeholder="yyyy-mm-dd - yyyy-mm-dd"
          ></date-picker>
        </div>

        <div class="row p-20 bg-white">
          <h2 class="my-20 w-100">Amount</h2>
          <div class="form-group">
            <b-form-group
              id="total-value-field"
              label="Total Value"
              label-for="total-value"
            >
              <b-input-group append="₹">
                <b-input
                  id="total-value"
                  placeholder="0.00"
                  v-money="money"
                ></b-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>

        <div class="row p-20 bg-white">
          <h2 class="my-20 w-100">Text Area</h2>
          <div class="form-group">
            <label for="textarea">Textarea - with label</label>
            <b-form-textarea
              id="textarea"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VMoney } from "v-money";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import AppTopbar from "@/components/layout/AppTopbar.vue";

export default {
  name: "DesignSystem",
  title: "Shyplite - Design System",
  data() {
    return {
      chkStatus: "Not Checked",
      selected: null,
      time3: null,
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      options: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      ddTestVm: {
        originalValue: [],
        ddTestSelectedOption: "Value1",
        disabled: false,
        readonly: false,
        visible: true,
        color: "",
        options: [
          {
            value: "Value1",
            text: "Value1Text",
          },
          {
            value: "Value2",
            text: "Value2Text",
          },
          {
            value: "Value3",
            text: "Value3Text",
          },
        ],
      },
    };
  },
  components: {
    AppTopbar,
    DatePicker,
    Multiselect,
  },
  directives: { money: VMoney },
  props: {
    msg: String,
  },
  methods: {
    downloadToast() {
      this.popToast(
        "requested",
        "Download requested",
        "Your download should start now."
      );
    },
    bookedToast() {
      this.popToast(
        "booked",
        "Order Booked",
        "Your order was booked successfully."
      );
    },
    failedToast() {
      this.popToast(
        "failed",
        "Booking Failed",
        "We couldn’t book this order now."
      );
    },
  },
};
</script>
